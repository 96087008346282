.flatCard,
.materialCard {
  background: #fff;
  border-radius: 4px;
  border: 0px solid rgb(189, 189, 189);
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
}

.materialCard {
  box-shadow: var(--tileBoxShadow);
}

.cardCont:first-child {
  padding-left: 0;
}

.cardCont:last-child {
  padding-right: 0;
}

.materialCard.dashCard {
  padding: 0;
}

.topHeading {
  padding: 12px 16px;
  /* border-bottom: 1px solid var(--borderColor); */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  /* margin-bottom: 8px; */
}

.tableCard {
  padding-bottom: 0 !important;
  overflow: hidden;
}

@media (max-width: 768px) {
  .cardCont {
    padding: 0;
  }
}

.topHeadingCard .float-right {
  margin-top: -7px;
}

.materialCard .dark {
  background-color: var(--theme);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px;
}

.materialCard .dark .title {
  color: #fff;
}