.material-symbols-outlined {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;

    &.outline {
        font-variation-settings:
            'FILL' 0,
    }

    &.thin {
        font-variation-settings:
            'wght' 300,
    }

    &.bold {
        font-variation-settings:
            'wght' 700,
    }
}