.app-orientation-message {
  position: fixed;
  top: 0;
  left: 0;
  background: #202020;
  width: 100%;
  height: 100%;
  z-index: 99999;

  .app-orientation-content {
    width: 330px;
    height: 399px;
    border-radius: 7px;
    background: var(--theme);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 16px;
      text-align: center;
      color: #d1d1d1;
      margin-top: 35px;
    }

    .app-orientation-icon {
      width: 124px;
      height: 165px;
      margin-top: 60px;
    }
  }

  @media (orientation: landscape) {
    display: none;
  }
}