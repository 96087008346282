.col,
[class^="col-"] {
  padding: 0 16px;
}

.row {
  /* margin: 0 -8px; */
  margin: 0px;
}

.col-side-bar {
  max-width: 12% !important;
}

.col-content {
  max-width: 88% !important;
}