.inheritWidth {
  width: 100%;
}

.inheritHeight {
  height: 100%;
}

.relativePos {
  position: relative;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.padding {
  &2 {
    padding: 2px;
  }

  &Right {
    &Four {
      padding-right: 4px;
    }

    &Six {
      padding-right: 6px;
    }

    &Eight {
      padding-right: 8px;
    }

    &Twelve {
      padding-right: 12px;
    }

    &Sixteen {
      padding-right: 16px;
    }
  }

  &Top {
    &Four {
      padding-top: 4px;
    }

    &Six {
      padding-top: 6px;
    }

    &Eight {
      padding-top: 8px;
    }

    &Twelve {
      padding-top: 12px;
    }

    &Sixteen {
      padding-top: 16px;
    }

    &Twenty {
      padding-top: 16px;
    }

    &Zero {
      padding-top: 0px !important;
    }
  }

  &Left {
    &Four {
      padding-left: 4px;
    }

    &Twelve {
      padding-left: 12px !important;
    }

    &Eight {
      padding-left: 8px !important;
    }

    &Sixteen {
      padding-left: 16px !important;
    }
  }

  &Bottom {
    &Sixteen {
      padding-bottom: 16px !important;
    }

    &Zero {
      padding-bottom: 0px !important;
    }
  }
}

.font {
  &vlg {
    font-size: 18px;
  }

  &lg {
    font-size: 16px;
  }

  &md {
    font-size: 14px;
  }

  &sm {
    font-size: 12px;
  }

  &22 {
    font-size: 22px;
  }

  &24 {
    font-size: 24px;
  }

  &26 {
    font-size: 26px;
  }

  &28 {
    font-size: 28px;
  }

  &19 {
    font-size: 19px;
  }

  &20 {
    font-size: 20px;
  }

  &vsm {
    font-size: 10px;
  }

  &13 {
    font-size: 13px;
  }

  &15 {
    font-size: 15px;
  }

  &12 {
    font-size: 12px;
  }

  &Weight300 {
    font-weight: 300;
  }

  &Weight400 {
    font-weight: 400;
  }

  &Weight500 {
    font-weight: 500;
  }

  &Weight600 {
    font-weight: 600;
  }
}

.img {

  &18 {
    width: 18px;
    height: 18px;
  }

  &20 {
    width: 20px;
    height: 20px;
  }

  &22 {
    width: 22px;
    height: 22px;
  }

  &24 {
    width: 24px;
    height: 24px;
  }

}

.text {
  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }
}

.italic {
  font-style: italic;
}


.hide {
  display: none;
}

// Flexbox
.flex {
  display: flex;
}

.visibilityHidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.inlineFlex {
  display: inline-flex;
}

.flexDirectionCol {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.flex-row-align-center {
  display: flex;
  align-items: center;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

// Alignment
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.noPointerEvents {
  pointer-events: none;
}

.text-right {
  text-align: right;
}

.float-right,
.float-left {
  display: inline-block;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

// Text color
.text-primary {
  color: $primary !important;
}

.text-danger {
  color: $danger !important;
  font-size: 12px !important;
}

// Text size
.small,
small {
  font-size: 12px;
}

.text-italic {
  font-style: italic;
  font-family: initial;
}

.error {
  font-weight: 300;
  margin-top: 5px;
  display: inline-flex;
  color: red;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.cursor-default {
  cursor: default !important;
}

// Transition css
// transition-property: width;
// transition-duration: 2s;
// transition-timing-function: ease-in;
// transition-delay: 0.5s;

.transitionHorizontal {
  -webkit-transition: width 0.5s linear 0.1s;
  transition: width 0.5s linear 0.1s;
  height: calc(100vh - 30px);
}

.horizontalTransition {
  -webkit-transition: width 0.5s linear 0.1s;
  transition: width 0.5s linear 0.1s;
}

.SlideInOuterContainter {
  position: absolute;
  right: 25px;
  top: 59px;
  max-width: calc(100vw - 150px + 31px);
  overflow: hidden;
  // bottom:10px;
  // height: auto;
  display: flex;
  z-index: 1;
  justify-content: flex-end;

  .slideButton {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(196, 196, 196, 0.6);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .TaskScreenOuterContainer {
    background: #fff;
    width: 34vw;
    float: right;
    border: 0.5px solid rgba(196, 196, 196, 0.5);
    box-shadow: -2px 0 8px 0 rgba(26, 21, 21, 0.15);

    .fr-box .fr-toolbar .fr-more-toolbar>.fr-command.fr-btn {
      margin: 4px 2px;
    }
  }

  .DialerPopup {
    box-shadow: -2px 0 8px 0 rgba(26, 21, 21, 0.15);
    right: 25px;
    position: fixed;
    background: #fff;
    width: 25vw;
    z-index: 23;
  }

  .IncomingCallPopup {
    right: calc(34vw + 50px);
  }

  .ProfileOuterContainer {
    background: #fff;
    height: calc(100vh - 30px - 44px);
    overflow: hidden;
    width: calc(100vw - 150px - 34vw);
    border: 0.5px solid rgba(196, 196, 196, 0.5);
    border-right: 0.5px solid rgba(196, 196, 196, 0.6);
    box-shadow: -2px 0 8px 0 rgba(26, 21, 21, 0.15);
  }
}

@media (max-width: 1200px) {
  .SlideInOuterContainter {
    right: 8px;
    max-width: calc(100vw - 70px);

    .TaskScreenOuterContainer {
      width: calc(44vw - 5px);
    }

    .IncomingCallPopup {
      right: calc(44vw - 5px + 50px);
    }

    .ProfileOuterContainer {
      width: calc(100vw - 70px - 44vw + 5px - 27px);
    }
  }
}

@media (min-width: 1600px) {
  .SlideInOuterContainter {
    // max-width: calc(1544px - 210px + 27px);

    .ProfileOuterContainer {
      width: 900px;
    }

    .TaskScreenOuterContainer {
      width: 550px;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textAlignLeft {
  text-align: left !important;
}

.textAlignRight {
  text-align: right !important;
}

.inheritPosition {
  position: inherit;
}

.noActionTextButton {
  border: none !important;
  background: none !important;
  color: grey !important;
}

.autoCursor {
  cursor: auto !important;
}

.backgroundImageRight {
  background-image: url("../images/scheduleMeeting.svg");
  height: calc(100vh - 38px);
  background-size: contain;
  // background-repeat: round;
  background-repeat: no-repeat;
  background-position: right;
}

.bigCardHeight {
  height: 370px;
}

.noBoxShadow {
  box-shadow: none;
}

.cardPadding {
  &.xy {
    padding: 12px 16px !important;
  }

  &.x {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  &.y {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &.two-four {
    padding: 20px 24px;
  }
}

.type-one-overflow {
  max-height: calc(100vh - 272px);
  min-height: calc(100vh - 272px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;
}

.type-two-overflow {
  max-height: calc(100vh - 199px);
  min-height: calc(100vh - 199px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;
}

.type-three-overflow {
  max-height: calc(100vh - 175px);
  min-height: calc(100vh - 175px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;
}

.type-four-overflow {
  max-height: calc(100vh - 155px);
  min-height: calc(100vh - 155px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;
}

.type-five-overflow {
  max-height: calc(100vh - 286px);
  min-height: calc(100vh - 286px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;

  .blank-tasks {
    height: calc(100vh - 384px);
  }
}

.type-six-overflow {
  max-height: calc(100vh - 323px);
  min-height: calc(100vh - 323px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;

  .blank-tasks {
    height: calc(100vh - 414px);
  }
}

.type-seven-overflow {
  max-height: calc(100vh - 352px);
  min-height: calc(100vh - 352px);
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 0px;

  .blank-tasks {
    height: calc(100vh - 384px);
  }
}

.noDataTableHeight {
  min-height: calc(100vh - 152px);
  max-height: calc(100vh - 152px);

  .blank-tasks {
    height: calc(100vh - 212px) !important;
  }
}

.reportTaskValues {
  font-size: 18px;
}

.marginLeftTwelve {
  margin-left: 12px !important;
}

.marginTopTwelve {
  margin-top: 12px !important;
}

.marginLeftSixteen {
  margin-left: 16px !important;
}

.data-number {
  font-size: 18px;
  font-weight: 400;
  color: #565757;
}

.margin {
  &Zero {
    margin: 0px !important;
  }

  &Bottom {
    &Twelve {
      margin-bottom: 12px;
    }

    &Six {
      margin-bottom: 6px;
    }

    &Four {
      margin-bottom: 4px;
    }

    &Two {
      margin-bottom: 2px;
    }

    &Eight {
      margin-bottom: 8px;
    }

    &Sixteen {
      margin-bottom: 16px;
    }

    &Thirty {
      margin-bottom: 30px;
    }

    &Twenty {
      margin-bottom: 20px;
    }

    &Zero {
      margin-bottom: 0px !important;
    }
  }

  &Top {
    &Twenty {
      margin-top: 20px;
    }

    &Eight {
      margin-top: 8px;
    }

    &Sixteen {
      margin-top: 16px !important;
    }

    &Six {
      margin-top: 6px !important;
    }

    &Four {
      margin-top: 4px !important;
    }

    &Zero {
      margin-top: 0px !important;
    }

    &Two {
      margin-top: 2px !important;
    }

    &Ten {
      margin-top: 10px !important;
    }

    &Thirty {
      margin-top: 30px;
    }

    &MinusTen {
      margin-top: -10px;
    }
  }

  &Right {

    &Four {
      margin-right: 4px;
    }

    &Twenty {
      margin-right: 20px;
    }

    &Thirty {
      margin-right: 30px;
    }

    &Six {
      margin-right: 6px;
    }

    &Eight {
      margin-right: 8px;
    }

    &Twelve {
      margin-right: 12px;
    }

    &Sixteen {
      margin-right: 16px;
    }
  }

  &Left {

    &Four {
      margin-left: 4px;
    }

    &Eight {
      margin-left: 8px;
    }

    &Twelve {
      margin-left: 12px;
    }

    &Six {
      margin-left: 6px;
    }

    &TwentyFour {
      margin-left: 24px;
    }
  }
}

.edit-prospect-page {
  width: 100%;

  .not-found-container {
    position: relative;
    min-height: 300px;
    top: 150px;
  }
}

// .text-ellipsis-break-spaces {
//   overflow: hidden;
//   white-space: break-spaces;
//   text-overflow: ellipsis;
// }

.marginTitle {
  margin-bottom: 8px;
}

.onHoverScroll {
  overflow: hidden;
  overflow-y: auto;
}

.button-disabled {
  background-color: rgb(226, 222, 222);
}

.valid-variable-field {
  background-color: #CCFFE6;
}

.invalid-variable-field {
  background: #F8CECC;
}

li.disabled {
  pointer-events: none;
}

.align-self {
  align-self: center;
}

.align-self-flex-start {
  align-self: flex-start;
}

.onHoverIcon {
  li {
    height: 36px;
    width: 36px;

    img {
      width: auto;
      height: 18px;
      mix-blend-mode: multiply;
    }

    &:hover {
      background-color: var(--iconHoverBackgroundColor);
      border-radius: 50%;

      // .material-symbols-outlined,
      // .material-icons,
      // img {
      //   transform: scale(1.1);
      // }

      // img {
      //   vertical-align: middle;
      // }
    }
  }
}

.customReactTooltip {
  max-width: 250px;
}

.mr-auto {
  margin: auto;
}

.mr-right {
  margin-right: auto;
}

.mr-left {
  margin-left: auto;
}

.word-break {
  word-break: break-word;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;

  &-1l {
    -webkit-line-clamp: 1;
  }

  &-3l {
    -webkit-line-clamp: 2;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

#bulkComposeSubject {
  width: calc(100% - 62px);
  overflow: hidden;
  overflow-x: auto;
  // height: 22px;
  padding: 0px;
  background: transparent;
  border: none;
  outline: none;
  border-radius: unset;
  padding-left: 12px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 2px;
  }
}

#bulkComposeSubject,
#templateSubjectEdit {
  color: #222;
  border: none;
  border-radius: 0px;
}

#templateSubjectEdit {
  border-bottom: 1px solid var(--borderColor);
}

.preview-email-header {
  #bulkComposeSubject {
    padding-left: 0px;
  }
}

#email-modal-id {
  #bulkComposeSubject {
    border-bottom: none;
  }
}

.moveCursor {
  cursor: grab;
}

.block {
  display: block;
}

.containerBorder {
  border: 1px solid var(--borderColor);
}

.border {
  &LeftNone {
    border-left: unset;
  }

  &RightNone {
    border-right: unset;
  }
}

.outline-none {
  outline: none;
}

.hidden {
  visibility: hidden;
}

.displayNone {
  display: none !important;
}

.background {
  &white {
    background: #FFF;
  }
}

.overflow {
  &All {
    overflow: auto
  }
}

.mailboxUnscynced {
  z-index: 19;
}

.highlighter {
  .highlighted {
    background-color: #E5F0FF;
    color: var(--theme-light);
    // box-shadow: -3px 0 0 #E5F0FF, 3px 0 0 #E5F0FF; // Creates an illusion of a wider span, while not affecting the actual width.
  }
}

.newBlue {
  color: #3874cb;
}

.appBlue {
  color: var(--theme-light)
}

.timeUnitContainer {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: fit-content;
  float: right;

  .timeUnit {
    cursor: pointer;
    padding: 0px 12px;
    letter-spacing: 0.02857em;
    height: 32px;
    display: flex;
    // margin-left: 4px;
    align-items: center;
  }

  .selected {
    background: var(--theme-light);
    color: #fff;
  }
}

.fff {
  color: #fff;
}

.zIndex {
  &1 {
    z-index: 1;
  }

  &2 {
    z-index: 2;
  }
}

.stepNo {
  margin-right: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  background-color: rgba(0, 170, 238, 0.2);
  color: #3874cb;
}

.noBorder {
  border: none !important;
}

.seperationLineAround {
  position: relative;
  display: flex;
  align-items: center !important;
  min-height: 20px;

  .text {
    z-index: 2;
    padding: 0px 6px;
    margin: 0px !important;
    background-color: white;
  }

  .sideline {
    height: 1px;
    background-color: #c4c4c4;
    width: 100%;
  }
}

.headline {
  position: relative;
  height: 20px;
  margin: 0px 0px 10px -5px;

  .sideline {
    height: 1px;
    background-color: #c4c4c4;
    width: 100%;
  }

  h3 {
    font-weight: 500;
    background-color: white;
    display: inline-block;
    height: 20px;
    position: absolute;
    left: 25px;
    top: 0;
    padding-right: 10px;
    line-height: 18px;
  }
}

.normalIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;

  &:hover {
    background-color: var(--iconHoverBackgroundColor);
  }
}

.tile {
  // border: none;
  box-shadow: var(--tileBoxShadow);
  border-radius: 4px;
  border: 0px solid rgb(189, 189, 189);
}

.buttonHover {
  &:hover {
    background-color: var(--iconHoverBackgroundColor);
  }
}