.fullscreen-spinner {
  width: 100vw;
  height: 100vh;
  // background-color: rgba(66, 66, 66, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 20;

  .spinnerInnerText {
    color: var(--theme-light);
  }
}

.inline-spinner {
  background: none;
  position: relative;
  top: unset;
  left: unset;
  text-align: center;
  z-index: 20;
  width: unset;
  height: unset;
  margin-left: 0px;
}