@mixin base-custom-modal {
  border-radius: 4px;
  background: #fff;
  background-size: contain;
  box-shadow: 0 25px 40px -20px rgba(0, 0, 0, 0.7);
}

.custom-modal-overlay {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-modal {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .custom-modal-content {
    font-size: 14px;
    line-height: 1.41;
    color: var(--textColorBlack);
    margin-right: 5px;

    >p {
      font-size: 16px;
      overflow-y: auto;
      max-height: 500px;
      word-break: break-word;
    }

    b {
      color: var(--theme);
      text-shadow: 2px 2px 2px #ccc;
      font-size: 16px;
      font-weight: 900;
    }

    &--secondary {
      margin-top: 10px;
      font-size: 14px;
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey;
    }
  }

  &--confirmation,
  &--alert {
    height: 180px;
    width: 440px;
    padding: 20px 16px;
    @include base-custom-modal;
    word-break: break-word;

    .custom-modal-title {
      margin: 0px;
      width: auto;
    }

    .custom-modal-content {
      text-align: justify;
    }

    #alert-modal {
      height: calc(100% - 20px);

      .alert-container {
        height: 100%;
      }

      .alert-body {
        padding: 12px 0px;
        height: calc(100% - 32px);
      }
    }
  }

  &--discard {
    width: 300px;
    padding: 15px;
    @include base-custom-modal;
    word-break: break-word;

    .custom-modal-content {
      text-align: justify;
    }

    .action-buttons {
      justify-content: flex-end;
      display: flex;
    }
  }

  &--popup {
    padding: 20px;
    @include base-custom-modal;
    word-break: break-word;
    overflow: hidden;

    .custom-modal-title {
      font-size: 20px;
    }
  }

  &--full-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    outline: none;
    top: 0;
    left: 0;
  }

  .btn-ok {
    height: 50px;
    width: 50px;
    border-radius: 4px;
    background: var(--theme);
    text-transform: uppercase;
    font-size: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;

    &:hover {
      color: #fff;
    }
  }

  &__delete {
    top: 2%;
    left: 50%;
    transform: translate(-50%, 10px);
  }
}

.ReactModalPortal {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: absolute;

  .full-screen-custom-modal {
    opacity: 0;
  }

  .custom-modal-overlay {
    transition: opacity 200ms ease-in-out;
    background: rgba(0, 0, 0, 0.3);

    &_after-open {
      opacity: 1;
    }

    &_before-close {
      opacity: 0;
    }

    &--alert {
      z-index: 99;
    }
  }
}

.custom-modal-container {
  // max-width: 80vw;
  max-width: 90vw;
  min-width: 380px;

  // margin-left: 30px;
  .extra-padding {
    padding: 0px 0px 8px;

    .terms-fa-times {
      top: 18px;
      height: 24px;
    }
  }
}

@media (max-width: 1200px) {
  .custom-modal-container {
    // margin-left: 5px;
  }
}

.custom-modal-container.user-detail-form-modal {
  max-width: 700px;
  padding: 20px;
  // background-color: white;
  border-radius: 15px;
}


.popup-modal,
.persona-form-view-modal,
.addValidation-modal,
.editPickList-modal {
  width: 500px;
}

.stage-form-view-modal {
  max-width: 506px;
}

.calllistModel {
  max-width: 700px;

  .custom-modal-title {
    margin: 0px;
  }
}

.addDisposition-modal,
.snoozeTask-modal,
.add-role-modal {
  max-width: 500px;
}

.add-role-modal {
  min-width: 500px;
}

.sendclaimlink-modal {
  max-width: 700px;
}

.messageList {
  li {
    padding: 8px;
    background-color: aliceblue;
    margin-bottom: 4px;
  }
}

.fieldMapperModal {
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  width: 350px;
}

.meetingDetails-modal {
  max-width: 500px;
  min-width: 500px;
}

#addMailbox-modal {
  width: 400px;
}

.custom-modal-container {
  &.dangerAction {
    .action-btn {
      .cta {
        background-color: var(--dangerLight);
        color: #fff;
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* Use this when we need to add padding and backgorund to popup */
.customModalLayout {
  border-radius: 4px;
  padding: 20px;
  background-color: white;
}

.shakeImage {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.editSubscriptionModal {
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  background-color: white;
}

.custom-modal--alert.cloneLinkTemplate {
  height: unset;
}

#addCredits-modal {
  width: 450px;

  .suggestionText {
    border-radius: 4px;
    background-color: #d7e6fb;
    padding: 8px 0px;
    width: 100%;
    text-align: center;
    color: var(--theme-light);
  }
}