.rowWhite {
    background-color: #fff;
}

.borderIconSet {
    padding: 4px;
    // border: solid 1px var(--borderColor);
    background-color: white;

    li {
        // border-right: 1px solid var(--borderColor);
        transition: all 0.3s ease-in-out;
        border-radius: 50%;

        &:hover,
        &:active {
            // border-radius: unset;
            background-color: var(--iconHoverBackgroundColor);

            // .material-symbols-outlined,
            // .material-icons,
            // img {
            //     transform: scale(1.2);
            // }
        }

        &:last-child {
            border-right: none;
        }
    }

    // .calendar_todayItem,
    // .refreshItem {
    //     &:hover {
    //         background-color: rgba(255, 182, 24, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(255, 182, 24, 1);
    //     }
    // }

    // .phoneItem {
    //     &:hover {
    //         background-color: rgba(112, 183, 56, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(112, 183, 56, 1);
    //     }
    // }

    // .editItem {
    //     &:hover {
    //         background-color: rgba(107, 166, 60, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(107, 166, 60, 1);
    //     }
    // }

    // .local_offerItem {
    //     &:hover {
    //         background-color: rgba(248, 207, 64, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(248, 207, 64, 1);
    //     }
    // }

    // .emailItem,
    // .play_arrowItem,
    // .micItem,
    // .downloadItem,
    // .searchItem {
    //     transition: all 0.3s ease-in-out;

    //     &:hover {
    //         background-color: rgba(30, 120, 240, 0.1);
    //         box-shadow: 0px 0px 1px 1px rgba(30, 120, 240, 1);
    //     }
    // }

    // .workflowItem {
    //     &:hover {
    //         background-color: rgba(66, 128, 217, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(66, 128, 217, 1);
    //     }
    // }

    // .edit_noteItem {
    //     &:hover {
    //         background-color: rgba(107, 79, 124, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(107, 79, 124, 1);
    //     }
    // }

    // .deleteItem {
    //     &:hover {
    //         background-color: rgba(222, 105, 105, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(222, 105, 105, 1);
    //     }
    // }

    // .snoozeItem {
    //     &:hover {
    //         background-color: rgba(120, 121, 241, 0.2);
    //         box-shadow: 0px 0px 1px 1px rgba(120, 121, 241, 1);
    //     }
    // }

}

.userLogoCont {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 8px;
    width: 32px;

    .activeIcon {
        background: transparent;
        border: 0.5px solid #585757;
        color: #585757;
        border-radius: 50%;
        height: 28px;
        width: 28px;

        &.inactive {
            background-color: unset;
            border: none;
        }
    }

    span {
        padding: 0px !important;
    }
}