.btn {

  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02857em;

  &--primary {
    border: none;
    color: white;
    background-color: $primary;
    // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);

    &__outline {
      border: 1px solid $primary;
      color: $primary;

      &:hover {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &__link {
      padding: 0;
      color: $primary;
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
  }

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(26, 115, 232, 0.45), 0 1px 3px 1px rgba(26, 115, 232, 0.3);
    outline: 1px solid transparent;
  }
}

.loadMoreBtn {
  width: 100%;
  border-radius: 0px;
  justify-content: center;
  display: flex;

  &:disabled {
    .sk-spinner {
      color: #fff;
    }
  }

  &:hover {
    background-color: var(--theme-light);
    color: #fff;
    font-weight: 500;
    border-radius: 0px;

    .sk-spinner {
      color: #fff;
    }
  }
}