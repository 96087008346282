@import "~react-toastify/dist/ReactToastify.css";

.Toastify__toast {
    background: #fff;
    border-radius: 4px;
    max-width: 396px;
    min-height: 48px;
    z-index: 1000;

    &.popupNotification {
        border-left: none;
        background: white;
        color: var(--darkcolor);
        min-height: 42px;

        .Toastify__close-button--light {
            color: var(--darkcolor);
        }
    }

    .toastCont {
        overflow-y: auto;
        max-height: 100px;

        h3 {
            font-weight: 500;
            color: var(--darkcolor);
        }

        p {
            font-size: 12px;
            color: var(--darkcolor);
        }

        a {
            outline: none;
            color: var(--darkcolor);
        }

        .ctaUnderscore {
            color: #fff;
            text-decoration: underline;
            font-size: 12px;
        }
    }

    &--success {
        border-left: 5px solid #6BA63C;

        .toastCont {
            h3 {
                color: #6BA63C
            }
        }
    }

    &--error {
        border-left: 5px solid #D74D4D;

        .toastCont {
            h3 {
                color: #D74D4D
            }
        }
    }

    &--warning {
        border-left: 5px solid var(--warningDark);

        .toastCont {
            h3 {
                color: var(--warningDark)
            }
        }
    }

    &--info {
        border-left: 5px solid #17a2b8;

        .toastCont {
            h3 {
                color: #17a2b8
            }
        }
    }
}

.Toastify__close-button--light {
    color: var(--darkcolor);
    opacity: 1;
}

.Toastify__toast-body {
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.Toastify__toast-container--bottom-left {
    bottom: 0px;
    left: 82px;
}

.Toastify__toast-container--top-right {
    top: 12px;
    right: 22px;
}