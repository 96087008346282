.hover-underline-animation {
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: var(--theme-light);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    &:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    &:hover {
        color: var(--theme-light);
    }
}