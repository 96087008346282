.alert-container {
  &__p {
    font-size: 14px;
    color: var(--darkcolor);
    line-height: 1.4;
    padding: 24px 0px 0px;
  }

  .lightFont {
    font-size: 12px;
  }

  .buttonSec {
    padding: 24px 0px 0px;
  }
}