:root {
  --theme: #001429;
  --theme-light: #1E78F0;
  --darkcolor: #565857;
  --realDarkColor: #000;
  --thinDarkColor: #565757;
  --dangerLight: #de6969;
  --warningDark: #FFB618;
  --warningLight: #f3ebcf;
  --borderColorForm: #dbdbdb;
  // --borderColor: rgb(222, 222, 222);
  --borderColor: rgba(0, 0, 0, 0.1);
  --iconHoverBackgroundColor: rgba(0, 0, 0, 0.04);
  --tileBoxShadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --tileBoxShadowHover: rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 2px 12px;
  // --borderColor: #ced4da;
  // --backgroundColor: #f2f3f3;
  --backgroundColor: #fff;
  --backgroundColorOnHover: rgba(233, 242, 255, 0.4);
  --textColorBlack: #212121;
  --textColorGrey: #9F9F9F;
  --disabledIcon: #c4c4c4;
  --green: #00CC00;
  --orange: #f56b07;
  --theme-green: #70b738;
}

body {
  overflow-x: hidden !important;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tr {
  outline: none;
}

label,
.label {
  font-size: 14px;
  margin: 4px 0px;

  .ctaUnderscore {
    &:hover {
      text-decoration: none;
      border: none;
    }
  }
}

.required {
  &:after {
    content: "*";
  }
}

/* labelstyling */
.normalLabel,
.greenLabel,
.redLabel,
.blueLabel,
.darkGreenLabel,
.greyLabel,
.darkGreyLabel,
.blackLabel,
.tealLabel,
.themeGreenLabel {
  background: #e7ffc0;
  padding: 4px 6px;
  border-radius: 4px;
}

.tealLabel {
  background: #03dac6;
  color: #fff;
}

.blackLabel {
  background: #909495;
  color: #fff;
}

.greyLabel {
  background: rgba(196, 196, 196, 0.1);
}

.darkGreyLabel {
  background: rgba(196, 196, 196, 0.5);
}

.darkGreenLabel {
  background: rgba(0, 255, 71, 0.5);
  color: #fff;
}

.themeGreenLabel {
  background: var(--theme-green);
  color: #fff;
}

.blueLabel {
  background-color: rgba(25, 118, 210, 0.1);
  color: var(--theme-light);
}

.redLabel {
  background: #ffe8e4;
}

/* Dot Stylings */
.redDot,
.greenDot,
.greyDot,
.transparentDot,
.orange {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.blacklistDot {
  background: var(--textColorBlack);
  display: inline-flex;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.greyDot {
  background: #c4c4c4;
}

.orange {
  background: var(--orange);
}

.transparentDot {
  background: transparent;
}

.greenDot {
  background: #27ae60;
  box-shadow: 0 0 0 rgba(39, 174, 96, 0.4);
  // animation: pulse 2s infinite;
}

.spacers {
  margin-top: 20px !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(39, 174, 96, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 174, 96, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.4);
    box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(39, 174, 96, 0);
    box-shadow: 0 0 0 10px rgba(39, 174, 96, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(39, 174, 96, 0);
    box-shadow: 0 0 0 0 rgba(39, 174, 96, 0);
  }
}

.ulClass {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* display: inline-block; */
}

h3 {
  font-size: 16px;
}

//TODO: Change this if it affects lot of things badly
h4 {
  font-size: 16px;
  // font-weight: bold;
}

p {
  margin: 0px;
}

.img-rounded {
  border-radius: 50%;
}

.highlight {
  color: var(--theme-light);
}

.noPadding {
  padding: 0 !important;
}

.nLeft {
  padding-left: 0 !important;
}

.nRight {
  padding-right: 0 !important;
}

@media (max-width: 768px) {
  .noPadding {
    padding: 0 15px !important;
  }

  .nLeft {
    padding-left: 15px !important;
  }

  .nRight {
    padding-right: 15px !important;
  }
}

button {
  cursor: pointer;
  font-size: 14px;
}

.cta,
.defBut,
.ctaOpt,
// .ctaOptHighLight,
.ctaDark,
.ctaLight,
.ctaUnderscore,
.ctaError {
  background: var(--theme-light);
  color: #fff;
  border: none;
  padding: 0px 12px;
  position: relative;
  font-size: 14px;
  height: 32px;
  font-weight: 600;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  cursor: pointer;

  &.smallFont {
    font-size: 14px;
  }
}

.cta,
// .ctaOptHighLight,
.ctaDark,
.ctaLight,
.ctaOpt {
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(26, 115, 232, 0.45), 0 1px 3px 1px rgba(26, 115, 232, 0.3);
    outline: 1px solid transparent;
  }

  &:disabled {
    box-shadow: none;
    outline: none;
  }
}

.clearBtn.ctaOpt,
.ctaOpt.borderNone {
  font-weight: 500;

  &:hover {
    box-shadow: none;
  }
}

.ctaLight {
  background: #0c2e57;
}

.ctaDark {
  background: var(--theme);
}

// .ctaOptHighLight,
.ctaOpt {
  font-weight: 600;
  background: 0 0;
  border: 1px solid var(--theme-light);
  color: var(--theme-light);
}

.ctaError {
  font-weight: 400;
  background: 0 0;
  border: 1px solid var(--dangerLight);
  color: var(--dangerLight);
  display: flex;
  align-items: center;
  padding: 0px 6px;
  font-size: 12px;

  .material-symbols-outlined {
    margin-right: 4px;
  }
}

.ctaUnderscore {
  color: var(--theme-light);
  padding: 0px;
  background: none;
  font-weight: 600;
  text-decoration: none;
  border: none;
  height: unset;

  &:hover {
    border-radius: 0px;
    border-bottom: 0.5px solid var(--theme-light);
  }
}


// Added this class and cta class does not give liberty to modify further
.filledButton {
  background: var(--theme-light);
  color: #fff;
  border-radius: 4px;
  border: none;
  padding: 8px 20px;
}

// Added this class as classname makes sense
.outlineButton {
  background: 0 0;
  border: 1px solid var(--theme-light);
  color: var(--theme-light);
}

.defBut {
  background: rgba(196, 196, 196, 0.1);
  color: var(--darkcolor);
}

.switch,
.switch--disable {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin-right: 4px;
}

.workflowToggleSwitch {
  width: calc(28px + 16px);
  height: calc(16px + 6px);
}

.switch--disable {
  pointer-events: none;
}

.switch input,
.switch--disable input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.workflowToggleSwitch {
  .slider {
    &:before {
      width: calc(12px + 6px);
      height: calc(12px + 6px);
    }

    &:after {
      position: absolute;
      content: "🔒";
      width: calc(12px + 6px);
      font-size: 14px;
      right: 2px;
      bottom: 2px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

.workflowToggleSwitch {
  input:checked+.slider:before {
    left: 4px;
  }

  input:checked+.slider:after {
    content: "";
  }
}

input:checked+.slider {
  background-color: #6BA63C;
}

input:focus+.slider {
  box-shadow: 0 0 1px #6BA63C;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  left: -6px;
}

.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
select,
.PhoneInput {
  padding: 6px 8px;
  line-height: 1.45;
  width: 100%;
  font-size: 14px;
  outline: none;
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  height: 32px;
  // background-color: #fdfdfd;

  &:hover {
    border-color: rgb(179, 179, 179);
  }

  &:focus {
    border-color: rgba(25, 118, 210, 0.85);
  }
}

.PhoneInput.PhoneInput--focus {
  border-color: rgba(25, 118, 210, 0.85);
}

input:read-only,
input:disabled,
.PhoneInput--disabled,
.react-select__control--is-disabled {
  border: unset !important;
  background-color: unset !important;
  padding-left: 0px;
}

.react-select__control--is-disabled {
  .react-select__value-container {
    padding: 0px !important;
  }
}

.PhoneInput {
  width: unset;
}

textarea {
  max-height: calc(100vh - 540px);
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-input-placeholder {
  /* Edge */
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 300;
}

::placeholder {
  font-weight: 400;
  opacity: 0.8;
}

.mainBody {
  position: absolute;
  left: 60px;
  min-height: 100vh;
  width: calc(100vw - 60px);
  padding: 15px 25px;
  color: var(--darkcolor);
  line-height: 1.4;
}

.mainBody.expandedNavbar {
  left: 160px;
  width: calc(100vw - 160px);
}

.pagination {
  // padding: 21px 15px;
  height: 52px;

  span {
    display: flex;

    div {
      font-weight: 500;
      margin: 0px 5px;
    }
  }

  .borderIcon {
    border-radius: 50%;
    color: #fff !important;
    background-color: var(--theme-light);

    &:first-child {
      margin-left: 5px;
    }

    &.noPointerEvents {
      border-radius: 50%;
      background-color: #e0e0e0;
    }
  }
}

.pagination button {
  border: none;
  background: rgba(0, 0, 0, 0.05);
  margin-left: 8px;
}

/* Scrollbar */
/* Just add overFlowCont class to any div which needs a scrollbar */
.overFlowCont::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 0px;
  background-color: #f3f3f3;
}

.overFlowCont::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: 0 0;
}

.overFlowCont {
  padding: 0 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.buttonSec {
  padding: 30px 0;
}

.overFlowCont::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: none;
  background-color: #c4c4c4;
}

.checklistSettings {
  margin-top: 12px;
}

.checklistSettings li {
  padding: 12px 0;
}

.smallTxt {
  font-size: 12px;
  display: block;
  font-weight: 400;
}

.wfList {
  margin: 8px 0;
  padding: 0px 24px;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 422px);
}

.wfList li {
  padding: 8px 8px 8px 20px;
  position: relative;
}

.wfList li .fa {
  position: absolute;
  left: 0;
}

// Donot remove this
.highlightBlue,
.highlightGray,
.highlightGreen {
  background-color: #03dac6;
  border-color: #03dac6;
  color: white;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 4px;
  // margin-left: 5px;
  text-align: center;
}

.highlightGray {
  background-color: #909495;
  border-color: #909495;
}

.highlightGreen {
  background-color: #b2ff59;
  border-color: #b2ff59;
}

.statText {
  font-weight: 400;
  font-size: 18px;
}

.insight-text {
  font-weight: 300;
  font-style: italic;
}

blockquote {
  border-left: 2px solid #565757;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  font-style: italic;
}

.profileTag,
.profileMain {
  text-transform: uppercase;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileMain {
  width: 100%;
  border: 1px solid #565757;
  font-weight: 500;
  color: #565757;
  padding: 10px;
}

.profileTag {
  padding: 10px;
  border: 1px solid var(--darkcolor);
  height: 28px;
  width: 28px;
  font-size: 12px;
  overflow-wrap: normal;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

.closeBtn {
  cursor: pointer;
  background: transparent;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0px;

  img {
    width: 18px;
    height: 18px;
  }
}

// @media (min-width: 1600px) {
//   .mainBody {
//     width: 1544px;
//     left: calc(50vw + 30px - 772px);
//   }
// }

@media (max-width: 1200px) {
  .mainBody {
    padding: 15px 8px;
  }
}

.borderNone {
  border: none !important;
  font-weight: normal;
}

a[href="{{{unsubscribe_url}}}"] {
  pointer-events: none;
}

.helperCont {
  margin: 0px !important;
  padding: 8px 0px;
  text-align: left;

  ul {
    list-style: disc;
    padding-top: 8px;
    padding-left: 18px;
    font-size: 12px;
    font-weight: 400;

    li {
      margin-bottom: 4px;
    }
  }
}

.clearBtn {
  padding: 0px;
  border: none;
  background: transparent;
}

.black {
  color: black;
}

.primaryFontColor {
  color: var(--thinDarkColor);
}