/* Tab Stylings */
.tabCont {
  padding: 0px;
  margin-bottom: 16px;
  // box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

  .leftTabCont {
    font-size: 14px;
    padding: 0px !important;
  }

  .customTab {
    display: inline;

    li {
      display: inline-block;
      padding: 0px 5px 8px;
      margin: 4px 12px 0;
      font-weight: 600;
      font-size: 16px;
      color: var(--thinDarkColor);
      cursor: pointer;
      transition: 0.4s;
      position: relative;

      .tabBadge {
        font-size: 12px;
        font-weight: 900;
        padding: 2px 4px;
        margin-left: 12px;
        color: var(--darkcolor);
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        // color: var(--theme-light);
        box-shadow: inset 0 -2px 0 0 var(--thinDarkColor);
        // box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
      }
    }

    li.activeTab {
      color: var(--theme-light);
      box-shadow: inset 0 -2px 0 0 var(--theme-light);
    }
  }
}