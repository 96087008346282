.settings-container {

  .subHeader {
    margin-top: -20px;
    padding-bottom: 24px;

    // * {
    //   font-weight: 300;
    // }
  }

  .expandableSearch {
    border: none !important;

    &:focus {
      border: none !important;
    }
  }

  input[type="radio"] {
    margin-left: 0px;
  }

  .SettingsSideBar {
    // max-width: 165px !important;

    >div:first-child {
      margin: 0;
      height: calc(100vh - 30px);
      overflow-y: scroll;
      background-color: #fff;
    }
  }

  h4 {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
  }

  span>h4 {
    text-transform: none;
    margin-bottom: 4px;
  }

  .bInfo,
  .settingSection {
    padding: 16px 0;
    border-bottom: 1px solid var(--borderColor);

    .ownedNumbers {
      img {
        width: 36px;
        height: 36px;
      }
    }
  }

  .settingSection:not(:first-child) {
    // padding: 24px 0 0;
  }

  .bInfo:first-child {
    padding-top: 0;
  }

  .bInfo:last-child,
  .settingSection:last-child {
    border: none;
  }

  .settingsCont {
    padding: 0px;
    max-height: calc(100vh - 144px);
    min-height: calc(100vh - 144px);
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom: 0px;
  }

  .customLabel {
    margin: 20px 0;
    margin-left: -8px;
  }

  .customLabel li {
    display: inline-block;
    width: 288px;
    position: relative;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: rgba(196, 196, 196, 0.1);
    margin: 8px;
  }

  .customLabel li .fa-trash {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 6px 8px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 3px;
    display: none;
  }

  .customLabel li .fa-pencil {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 6px 8px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 3px;
    display: none;
  }

  .customLabel li:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  }

  .customLabel li:hover .fa-trash {
    display: block;
  }

  .customLabel li:hover .fa-pencil {
    display: block;
  }

  .customFields {
    padding: 0;
  }

  .mainTableHeader {
    position: relative;
    width: 150px !important;
  }

  .mainTableHeader h4 {
    position: absolute;
    top: 16px;
  }

  .customFields input[type="text"] {
    width: 288px;
    margin-bottom: 12px;
    display: block;
  }

  .bInfo label {
    display: block;
    margin: 0px 0px 8px;
  }

  .bInfo input,
  .bInfo select {
    /* margin-bottom:20px; */
    max-width: 100% !important;
  }

  /* Stages & Persona section */

  .personaLabel {
    margin-top: 12px;
  }

  .personaLabel li {
    display: inline-flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 12px 16px;
    // padding-left: 48px;
    border-radius: 4px;
    /* background-color: rgba(196, 196, 196, 0.1); */
    // margin: 4px 0;
    background-color: #fcfcfc;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .personaLabel li {
    padding-left: 12px;
  }

  // .stagesLabel li:before {
  //   content: "::";
  //   position: absolute;
  //   left: 8px;
  //   color: rgba(0, 0, 0, 0.15);
  //   top: 50%;
  //   transform: translateY(-65%);
  //   font-weight: bold;
  //   font-size: 1.5em;
  // }
  // .stagesLabel li:after {
  //   content: "";
  //   background: #c4c4c4;
  //   position: absolute;
  //   width: 16px;
  //   height: 16px;
  //   border-radius: 50%;
  //   left: 24px;
  //   top: 6px;
  // }

  .personaLabel li .fa {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    padding: 6px 8px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-55%);
    display: none;
  }

  .personaLabel li .fa-pencil {
    right: 44px;
  }

  .personaLabel li:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color: #fcfcfc;
  }

  .personaLabel li:hover .fa {
    display: block;
  }

  .bInfo .col-12 {
    position: relative;
  }

  .inputCont {
    margin-bottom: 16px;
  }

  .smallTxt {
    margin: 4px 0px 8px;
    font-style: italic;
  }

  /* email */
  .greyLabel {
    width: 360px;
    margin: 8px 0;
    position: relative;
  }

  .greyLabel .fa {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Settings You -> Calendar */
  .timeBlock {
    width: 360px;
    height: 39px;
    border-radius: 4px;
    text-align: center;
    position: relative;
    margin: 10px 0;
    margin-left: 40px;
    border: dashed 1px #c4c4c4;
    display: inline-block;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .timeBlock .blueLabel {
    background: rgba(25, 118, 210, 0.1);
    width: 150px;
    height: 39px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .typeDesc {
    font-weight: 300;
  }
}